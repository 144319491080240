import React from 'react'

import Grid from '@material-ui/core/Grid'
import { Banner } from '../component/Banner'
import { Display } from '../component/page_component/Display'
import { Estimate } from '../component/page_component/Estimate'
import { HighLights } from '../component/page_component/HighLights'
import { Layout } from '../component/Layout'
import { PageProps } from 'gatsby'
import { Technology } from '../component/page_component/Technology'
import { useIntl } from 'gatsby-plugin-intl'
import { useStaticQuery, graphql } from 'gatsby'

const QUERY = graphql`
  query SoldQuery {
    backoffice {
      publicPropertyList(
        limit: 1000
        isProtected: false
        isOffmarket: false
        ordering: "-date_vente"
        statutVente: "VEN_B"
      ) {
        edges {
          node {
            id
            pk
            createdAt
            ville
            autreVille
            reference
            propertyType
            nombreChambres
            propertyTypeDisplay
            propertyTypeSlug
            nombrePieces
            prixAffichageFai
            prixLoyer
            prixConfidentiel
            photosKp
            surfaceHabitable
            photosKp
            infos
            titreAr
            titreEn
            titreEs
            titreFr
            titrePt
            titreRu
            titreZh
            venduPar
          }
        }
      }
    }
  }
`

const Sell: React.FC<PageProps> = (props) => {
  const {
    backoffice: {
      publicPropertyList: { edges },
    },
  } = useStaticQuery(QUERY)
  const { location } = props

  const soldByKretz = edges.filter((property: any) => property.node.venduPar == 'KRE')

  const intl = useIntl()
  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleSell' })}>
      <Banner
        src="sell_banner.jpg"
        innerStyle={{
          zIndex: '1100',
          marginTop: '-150px',
        }}
      />
      <Grid container justifyContent="center" id="sell-with-kretz">
        <HighLights
          title={intl.formatMessage({ id: 'sell.title' })}
          detail={intl.formatMessage({ id: 'sell.detail' })}
          linkTxt={intl.formatMessage({ id: 'sell.link' })}
          href={`/${intl.locale}/agency#team`}
          sell={true}
        />
      </Grid>
      <Grid container id="estimate-my-property">
        <Estimate defaultOpen />
      </Grid>
      <Technology />
      <Grid container id="sold-goods">
        <Display title={intl.formatMessage({ id: 'goodsSold' })} edges={soldByKretz} />
      </Grid>
    </Layout>
  )
}

export default Sell
